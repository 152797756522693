import React, { Component } from 'react';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';
import RuntimeContext, { Actions, Host } from './runtime-context';
import { RootState } from '../../../components/MySubscriptions/state';

type MapRuntimeToProps<P> = (state: RootState, props: P, actions: Actions, host: Host) => any;

function connect<P>(mapRuntimeToProps: MapRuntimeToProps<P>) {
  return function <PC>(WrappedComponent: React.ComponentType<PC>) {
    class ConnectedComponent extends Component<P> {
      static contextType = RuntimeContext;
      static displayName = `connect(${getDisplayName(WrappedComponent)})`;

      render() {
        const props = mapRuntimeToProps(this.context.state, this.props, this.context.actions, this.context.host);
        return <WrappedComponent {...this.props} {...props} />;
      }
    }

    hoistNonReactStatics(ConnectedComponent, WrappedComponent);

    return ConnectedComponent;
  };
}
export default connect;
