import React from 'react';
import { TextSubscriptionInfo } from '../TextSubscriptionInfo';
import { classes } from './Line.st.css';
import Info from 'wix-ui-icons-common/on-stage/Info';
import { Popover, Text } from 'wix-ui-tpa';

export interface LineObject {
  text: string;
  tooltipText?: string | React.ReactElement<any>;
  dataHook?: string;
}

export const LineItem = ({ line }: { line: LineObject }) => {
  return (
    <div className={classes.line}>
      <TextSubscriptionInfo dataHook={line.dataHook}>{line.text}</TextSubscriptionInfo>
      {line.tooltipText && (
        <div className={classes.tooltipWrapper}>
          <Popover placement="top" dataHook={`tooltip-${line.dataHook}`}>
            <Popover.Element>
              <div data-hook={`${line.dataHook}-info-icon`}>
                <Info />
              </div>
            </Popover.Element>
            <Popover.Content>
              {typeof line.tooltipText === 'string' ? <Text>{line.tooltipText}</Text> : line.tooltipText}
            </Popover.Content>
          </Popover>
        </div>
      )}
    </div>
  );
};
